import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HexColorPicker, RgbaStringColorPicker, HexColorInput, HexAlphaColorPicker } from "react-colorful";
import rgbHex from 'rgb-hex';
import Dropdown from "./Dropdown";
import useDebounce from "./UseDebounce";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {addColorToPallete} from "../../actions/UserActions";

function ColorPallete(props) {
	const {item, value, type, updateAction, dropdown, isLeft} = props;
	const dispatch = useDispatch();
	const [color, setColor] = useState(value ? value : '');
	const throttledColor = useDebounce(color, 600);
	const {currentCampaignId, colorPallete} = useSelector(state => state.user);
	const hexToRGB = (hex) => {
		// Remove the "#" character
		hex = hex.replace("#", "");

		// check for the length of hex input
		if(hex.length === 3){
			// Expand hex code to 6 characters if the input is in the format of #RGB
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		} else if(hex.length === 4){
			// Expand hex code to 8 characters if the input is in the format of #RGBA
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]+ hex[3]+ hex[3];
		}

		// Convert the hex string to an array of integers
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);

		// check for the presence of alpha channel if the input is in the format of RRGGBBAA
		let a = parseInt(hex.substring(6, 8), 16);
		a = isNaN(a) ? 1 : a/255; //if the input is RRGGBB return a value of 1
		a = Math.round(a*100)/100;

		// Return an RGB object
		return `rgba(${r}, ${g}, ${b}, ${a})`;
	}

	const setFormattedColor = (hexInputColor) => {
		if(type === "hex"){
			setColor(hexInputColor)
		}
		else if(type === "rgba"){
			setColor(hexToRGB(hexInputColor))
		}
	}
	const isHex = color => {
		var reg= /^#([0-9a-f]{3}){1,2}$/i;
		return reg.test(color);
	}
	const hexColor = color ? (type === "hex" ? color: rgbHex(color)): color
	
	const reset = () => {
		updateAction(item, null, props.extra);
	}
	const forceSetColor = (c) => {
		setColor(c);
	}
	useEffect(() => {
		let c = throttledColor;
		if(isHex(c) && (type === 'rgba') ){
			c = hexToRGB(c);
		}
		if(c) c = c.toLowerCase(c)
		if(updateAction && (color !== value) && color){
			updateAction(item, c, props.extra)
			/* Checking if the color is already in the pallete */
			let f = false;
			colorPallete[currentCampaignId][type].forEach( cl => {
				if(cl === c) f = true;
			})
			if(!f) dispatch(addColorToPallete(c, type, currentCampaignId))
		}
	}, [throttledColor])

	useEffect(() => {
		if(props.value !== throttledColor) setColor(props.value ? props.value : '')
	}, [props])
	return (
		<div className="colorpicker__wrap">
			{ color && <span className="material-icons colorpicker__reset pointer" onClick={reset}>clear</span>}
			<Dropdown dropup={dropdown ? false : true}>
				<div className="dropdown-trigger colorpicker">
					<div className="colorpicker__color colorpicker__color-lg text--center" style={{ background: color }}>
						{ !color && <span className="material-icons font--20 mtop--5">invert_colors</span> }
					</div>
				</div>
				<div className="dropdown__menu" style={{minWidth: '236px', marginLeft: isLeft && '-236px'}}>
					<div className="dropdown__content colorpicker__dropdown">
						<div className="colorpicker__picker" >
							{ type === 'hex' && <HexColorPicker color={color} onChange={setColor} /> }
							{ type === 'rgba' && <RgbaStringColorPicker color={color} onChange={setColor} /> }
							<div className="react-colorful__input text--center">
								<span className="vm--">#&nbsp;</span>
								<span className="vm--align">
									<HexColorInput onClick={(e) => e.stopPropagation()} color={hexColor} onChange={setFormattedColor} alpha={type === 'rgba'}  />
								</span>
								<CopyToClipboard text={hexColor}>
									<button className="btn btn-secondary btn-sm">
										<span className="material-icons font--22">
											content_copy
										</span>
									</button>
								</CopyToClipboard>
							</div>
							<div className="mtop--20 colorpicker__picker-recent">
								<label className="text--bold font--11">Recently used ({type})</label>
								<div className="colorpicker flex flex--row flex--wrap">
									{colorPallete[currentCampaignId][type].map((c, i) => {
										return (
											<div key={`color__${i}`} onClick={() => forceSetColor(c)} className="colorpicker__color text--center mleft--5 mtop--5" style={{ background: c }}>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dropdown>
		</div>
	);
}

export default ColorPallete;
